.admin-update-report {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    flex-direction: column;
    min-width: 665px;

    .container {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        .hidden {
            display: none;
        }

        .box {
            width: 25vw;
            min-width: 270px;
            padding-bottom: 10vh;


            .title {
                font-size: 2em;
                text-align: center;
                width: 100%;
            }

            .subtitle {
                text-align: center;
                width: 100%;
                margin-bottom: 10px;
            }

            .input-title {
                padding-left: 2px;
                padding-bottom: 0px !important;
            }

            .text-input {
                width: 100%;
                text-align: center;
            }

            .checkbox-input {
                width: 5%;
                text-align: center;
            }

            .reports {
                min-height: 150px;
                padding: 5px;
                height: 20vh;
                border: 1px solid lightgray;
                overflow: auto;
            }

            .update-report-button {
                width: 50%;
                background-color: #0099d5;
                border-color: #0099d5;
            }

            .delete-report-button {
                /*margin: 2px;*/
                width: 50%;
                background-color: #f44e42;
                border-color: #f44e42;
            }

            .button-container {
                width: 100%;
                display: inline-flex;
            }

            .spacer {
                margin-left: 1%;
                margin-right: 1%;
            }
        }
    }
}
