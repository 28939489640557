body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  transition: 0.5s ease-in-out; }

.bb-navbar {
  background-color: white; }
  .bb-navbar .navbar-toggler {
    border-color: #38A2E8; }
  .bb-navbar .logo-img {
    max-height: 8vh;
    margin-left: 10px; }
  .bb-navbar .logo-center {
    /*position: absolute;
        display: block;
        left: 48%;
        margin-left: -50px !important;*/
    /*transform: translateX(-50%);
        left: 50%;
        position: absolute;
        vertical-align: middle;
        display: inline !important;*/
    max-height: 6vh; }
  .bb-navbar .center {
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
    align-self: center; }
  .bb-navbar .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #38A2E8;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 400;
    text-transform: uppercase;
    transition: 0.3s ease-in-out; }
    .bb-navbar .nav-item a {
      color: #38A2E8; }
      .bb-navbar .nav-item a:hover {
        color: #888888;
        text-decoration: none; }
  .bb-navbar .dropdown-link {
    color: #0099d5 !important;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase; }
  .bb-navbar .home-link {
    color: #0099d5; }
  .bb-navbar .dropdown-menu {
    left: auto !important; }

.login-page {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px; }
  .login-page .header {
    align-self: flex-start;
    display: flex;
    width: 100vw;
    min-width: 665px; }
    .login-page .header .header-left {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 50%;
      padding: 20px; }
      .login-page .header .header-left img {
        object-fit: contain;
        max-height: 75px; }
    .login-page .header .header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 50%;
      padding: 20px; }
      .login-page .header .header-right img {
        object-fit: contain;
        max-height: 75px; }
  .login-page .login-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center; }
    .login-page .login-container .login-title {
      text-align: center;
      font-size: 2em;
      margin: 5px; }
    .login-page .login-container .login-text {
      text-align: center;
      margin: 5px; }
    .login-page .login-container .login-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 20vw;
      min-width: 270px; }
      .login-page .login-container .login-box input {
        margin: 5px;
        text-align: center; }
      .login-page .login-container .login-box .login-button {
        margin: 5px;
        width: 100%;
        background-color: #0099d5;
        border-color: #0099d5; }

.new-password-page {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px; }
  .new-password-page .header {
    align-self: flex-start;
    display: flex;
    width: 100vw;
    min-width: 665px; }
    .new-password-page .header .header-left {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 50%;
      padding: 20px; }
      .new-password-page .header .header-left img {
        object-fit: contain;
        max-height: 75px; }
    .new-password-page .header .header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 50%;
      padding: 20px; }
      .new-password-page .header .header-right img {
        object-fit: contain;
        max-height: 75px; }
  .new-password-page .new-password-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center; }
    .new-password-page .new-password-container .new-password-box {
      width: 20vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .new-password-page .new-password-container .new-password-box .new-password-title {
        text-align: center;
        font-size: 2em;
        margin: 5px; }
      .new-password-page .new-password-container .new-password-box .new-password-text {
        text-align: center;
        margin: 5px; }
      .new-password-page .new-password-container .new-password-box input {
        text-align: center;
        width: 100%;
        margin: 5px; }
      .new-password-page .new-password-container .new-password-box .save-button {
        width: 100%;
        margin: 5px;
        background-color: #0099d5;
        border-color: #0099d5; }

.forgot-page {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px; }
  .forgot-page .header {
    align-self: flex-start;
    display: flex;
    width: 100vw;
    min-width: 665px; }
    .forgot-page .header .header-left {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 50%;
      padding: 20px; }
      .forgot-page .header .header-left img {
        object-fit: contain;
        max-height: 75px; }
    .forgot-page .header .header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 50%;
      padding: 20px; }
      .forgot-page .header .header-right img {
        object-fit: contain;
        max-height: 75px; }
  .forgot-page .forgot-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center; }
    .forgot-page .forgot-container .forgot-box {
      width: 20vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .forgot-page .forgot-container .forgot-box .forgot-title {
        text-align: center;
        font-size: 2em;
        margin: 5px; }
      .forgot-page .forgot-container .forgot-box .forgot-text {
        text-align: center;
        margin: 5px; }
      .forgot-page .forgot-container .forgot-box input {
        text-align: center;
        width: 100%;
        margin: 5px; }
      .forgot-page .forgot-container .forgot-box .reset-button {
        width: 100%;
        margin: 5px;
        background-color: #0099d5;
        border-color: #0099d5; }

.home-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100vw; }
  .home-page .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1; }
  .home-page .title {
    font-size: 2em;
    text-align: center;
    width: 100%; }
  .home-page .subtitle {
    text-align: center;
    width: 100%;
    margin-bottom: 10px; }
  .home-page .submit-button {
    width: 100%;
    background-color: #0099d5;
    border-color: #0099d5; }
  .home-page .report {
    width: 100%;
    height: 99%; }
  .home-page #container {
    text-align: center;
    height: 100%;
    width: 100%; }
  .home-page .buttonContainer {
    /*align-self: flex-start;*/
    padding-left: 5px;
    /*width: 100%;*/ }
  .home-page .backContainer {
    padding-right: 5px;
    /*float: right;*/ }
  .home-page .reportUtils {
    display: flex;
    /* establish flex container */
    flex-direction: row;
    /* default value; can be omitted */
    flex-wrap: nowrap;
    /* default value; can be omitted */
    justify-content: space-between;
    height: 25px; }
  .home-page .backButton {
    border: none;
    padding: 0;
    background: none;
    color: #0099d5; }
  .home-page #fullscreen {
    color: #0099d5;
    border-color: #0099d5; }
  .home-page i:hover {
    background-color: #FFC433; }
  .home-page .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: white;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200000;
    /*.fade-in {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #FFFFFF;
            top: 0;
            right: 0;
            animation: heartRateIn 2.5s linear infinite;
        }

        .fade-out {
            position: absolute;
            width: 120%;
            height: 100%;
            top: 0;
            left: -120%;
            animation: heartRateOut 2.5s linear infinite;
            background: rgba(255, 255, 255, 1);
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%);
        }*/ }
    .home-page .loading-screen .logo-img {
      max-height: 8vh;
      margin-left: 10px; }
    .home-page .loading-screen .space {
      margin-top: 10px;
      position: relative;
      align-items: center; }

@keyframes heartRateIn {
  0% {
    width: 100%; }
  50% {
    width: 0; }
  100% {
    width: 0; } }

@keyframes heartRateOut {
  0% {
    left: -120%; }
  30% {
    left: -120%; }
  100% {
    left: 0; } }

.admin-new-user {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px; }
  .admin-new-user .container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center; }
    .admin-new-user .container .box {
      width: 20vw;
      min-width: 270px;
      padding-bottom: 10vh; }
      .admin-new-user .container .box .title {
        font-size: 2em;
        text-align: center;
        width: 100%; }
      .admin-new-user .container .box .subtitle {
        text-align: center;
        width: 100%;
        margin-bottom: 10px; }
      .admin-new-user .container .box .input-title {
        width: 100%;
        padding-left: 2px; }
      .admin-new-user .container .box .text-input {
        width: 100%;
        text-align: center; }
      .admin-new-user .container .box .sites {
        min-height: 150px;
        padding: 5px;
        height: 20vh;
        border: 1px solid lightgray;
        overflow: auto; }
      .admin-new-user .container .box .create-user-button {
        width: 100%;
        background-color: #0099d5;
        border-color: #0099d5; }

.admin-edit-user {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px; }
  .admin-edit-user .container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center; }
    .admin-edit-user .container .hidden {
      display: none; }
    .admin-edit-user .container .box {
      width: 20vw;
      min-width: 270px;
      padding-bottom: 10vh; }
      .admin-edit-user .container .box .title {
        font-size: 2em;
        text-align: center;
        width: 100%; }
      .admin-edit-user .container .box .subtitle {
        text-align: center;
        width: 100%;
        margin-bottom: 10px; }
      .admin-edit-user .container .box .input-title {
        width: 100%;
        padding-left: 2px; }
      .admin-edit-user .container .box .text-input {
        width: 100%;
        text-align: center; }
      .admin-edit-user .container .box .sites {
        min-height: 150px;
        padding: 5px;
        height: 20vh;
        border: 1px solid lightgray;
        overflow: auto; }
      .admin-edit-user .container .box .select-box {
        top: auto;
        bottom: 100%; }
      .admin-edit-user .container .box .create-user-button {
        width: 100%;
        background-color: #0099d5;
        border-color: #0099d5; }

.admin-update-report {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px; }
  .admin-update-report .container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center; }
    .admin-update-report .container .hidden {
      display: none; }
    .admin-update-report .container .box {
      width: 25vw;
      min-width: 270px;
      padding-bottom: 10vh; }
      .admin-update-report .container .box .title {
        font-size: 2em;
        text-align: center;
        width: 100%; }
      .admin-update-report .container .box .subtitle {
        text-align: center;
        width: 100%;
        margin-bottom: 10px; }
      .admin-update-report .container .box .input-title {
        padding-left: 2px;
        padding-bottom: 0px !important; }
      .admin-update-report .container .box .text-input {
        width: 100%;
        text-align: center; }
      .admin-update-report .container .box .checkbox-input {
        width: 5%;
        text-align: center; }
      .admin-update-report .container .box .reports {
        min-height: 150px;
        padding: 5px;
        height: 20vh;
        border: 1px solid lightgray;
        overflow: auto; }
      .admin-update-report .container .box .update-report-button {
        width: 50%;
        background-color: #0099d5;
        border-color: #0099d5; }
      .admin-update-report .container .box .delete-report-button {
        /*margin: 2px;*/
        width: 50%;
        background-color: #f44e42;
        border-color: #f44e42; }
      .admin-update-report .container .box .button-container {
        width: 100%;
        display: inline-flex; }
      .admin-update-report .container .box .spacer {
        margin-left: 1%;
        margin-right: 1%; }

.admin-new-report {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px; }
  .admin-new-report .container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center; }
    .admin-new-report .container .box {
      width: 30vw;
      min-width: 270px;
      padding-bottom: 10vh; }
      .admin-new-report .container .box .title {
        font-size: 2em;
        text-align: center;
        width: 100%; }
      .admin-new-report .container .box .subtitle {
        text-align: center;
        width: 100%;
        margin-bottom: 10px; }
      .admin-new-report .container .box .input-title {
        padding-left: 2px;
        padding-bottom: 0px !important; }
      .admin-new-report .container .box .text-input {
        width: 100%;
        text-align: center; }
      .admin-new-report .container .box .sites {
        min-height: 150px;
        padding: 5px;
        height: 20vh;
        border: 1px solid lightgray;
        overflow: auto; }
      .admin-new-report .container .box .create-report-button {
        width: 100%;
        background-color: #0099d5;
        border-color: #0099d5; }

.not-found {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px; }
  .not-found .header {
    align-self: flex-start;
    display: flex;
    width: 100vw;
    min-width: 665px; }
    .not-found .header .header-left {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 50%;
      padding: 20px; }
      .not-found .header .header-left img {
        object-fit: contain;
        max-height: 75px; }
    .not-found .header .header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 50%;
      padding: 20px; }
      .not-found .header .header-right img {
        object-fit: contain;
        max-height: 75px; }
  .not-found .page-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center; }
    .not-found .page-container .page-title {
      text-align: center;
      font-size: 2em;
      margin: 5px; }
    .not-found .page-container .page-text {
      text-align: center;
      margin: 5px; }
    .not-found .page-container .page-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 20vw;
      min-width: 270px; }
      .not-found .page-container .page-box input {
        margin: 5px;
        text-align: center; }
      .not-found .page-container .page-box .home-button {
        margin: 5px;
        width: 100%;
        background-color: #F88E28;
        border-color: #F88E28; }

